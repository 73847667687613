.side-nav {
  width: 100%;
  height: 400px;
  overflow-y: auto;
  background-color: white;
  border-right: 1px solid rgb(238, 238, 238);

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  .menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 5px;

    cursor: pointer;

    &:hover {
      background-color: #fafafa;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .menu-label {
    font-size: 16px;
    font-weight: 500;
    color: #333;
  }

  .arrows {
    margin-left: auto;

    svg {
      font-size: 20px;
      color: #333;
    }
  }

  .side-menu-container {
    padding: 10px;

    .side-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .arrows {
        margin-left: auto;

        svg {
          font-size: 16px;
        }
      }
    }
  }

  .sub-menu {
    padding-left: 20px;
  }

  .show {
    display: block;
  }

  .hide {
    display: none;
  }
}

/* Mobile-specific styles */
@media screen and (min-width: 320px) and (max-width: 1023px) {
  .side-nav {
    border-right: none;
    height: 77vh !important;
    width: 99% !important;
  }
  .sub-menu {
    .ant-menu {
      border-inline-end: none !important;
    }
  }
}

body .ant-drawer.mobile-side-nav-drawer .ant-drawer-header-title > button {
  display: block !important;
}

.mobile-side-nav-drawer .ant-drawer-close svg {
  color: #000000;
}
// .side-menu * {
//   width: 100%;
// }
.no-children .ant-space-item,
.no-children .ant-space-item a {
  width: 100%;
  display: flex;
}
