.search-bar {
  .ant-input-wrapper {
    // .ant-select {
    //   width: 100% !important;
    // }

    .ant-input-affix-wrapper {
      border: none !important;
      border-radius: 0px !important;
      padding: 0 5px 0 5px !important;
      //background: #f1f1f1 !important;

      .ant-input-suffix {
        .ant-input-clear-icon {
          svg {
            color: white !important;
            font-size: 16px !important;
            background: black !important;
            border-radius: 25px !important;
          }
        }
      }
    }
    input.ant-input,
    button {
      border: none !important;
      border-radius: 0px !important;
      background: #f1f1f1 !important;
    }

      .MobileSearch{
        .searchInput{
          padding: 12px;
        }
      }

    input {
      border: none !important;
      border-radius: 0 !important;
    }

    span.ant-input-group-addon {
      border-radius: 0 !important;
      button.ant-input-search-button {
        border: none !important;
        height: 30px;
      }
    }
  }
  .ant-select-clear {
    top: 44% !important;
    svg {
      font-size: 17px;
    }
  }

  .searchInput {
    border: none !important;
    box-shadow: none !important;
    border-radius: 5px !important;
    padding: 2px;
    height: 38px;
    background: #f1f1f1 !important;

    input {
      color: black !important;
      font-size: 14px !important;
      background: #f1f1f1 !important;
    }
  }
}
